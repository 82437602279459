body {
    padding: 0;
    margin: 0;
    background-color: #D8E7EB;
}

* {
    font-family: 'Poppins', sans-serif;
}

*, html {
    scroll-behavior: smooth !important;
}